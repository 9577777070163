import Validation from "%/Validation";

export const OrderContentValidation = new Validation([
  {
    key: ["plan"],
    name: "プラン",
    rules: [{ rule: "need_select" }],
  },
  {
    key: ["pay_method"],
    name: "お支払い方法",
    rules: [{ rule: "need_select" }],
  },
  {
    key: ["is_rental"],
    name: "端末レンタルの有無",
    rules: [{ rule: "need_select" }],
  },
]);

export const OrderCardValidation = new Validation([
  {
    key: ["card", "number"],
    name: "カード番号",
    rules: [{ rule: "need_input" }, { rule: "number" }],
  },
  {
    key: ["card", "name"],
    name: "カード名義",
    rules: [{ rule: "need_input" }],
  },
  {
    key: ["card", "yearmonth"],
    name: "有効期限",
    rules: [{ rule: "need_yearmonth" }],
  },
  {
    key: ["card", "cvc"],
    name: "セキュリティーコード",
    rules: [{ rule: "need_input" }, { rule: "number" }],
  },
]);

export const OrderChangePayMethodContentValidation = new Validation([
  {
    key: ["pay_method"],
    name: "お支払い方法",
    rules: [{ rule: "need_select" }],
  },
]);

export const OrderChangePayMethodCardValidation = new Validation([
  {
    key: ["card", "number"],
    name: "カード番号",
    rules: [{ rule: "need_input" }, { rule: "number" }],
  },
  {
    key: ["card", "name"],
    name: "カード名義",
    rules: [{ rule: "need_input" }],
  },
  {
    key: ["card", "yearmonth"],
    name: "有効期限",
    rules: [{ rule: "need_yearmonth" }],
  },
  {
    key: ["card", "cvc"],
    name: "セキュリティーコード",
    rules: [{ rule: "need_input" }, { rule: "number" }],
  },
]);

export const OrderChangePlanValidation = new Validation([
  {
    key: ["plan"],
    name: "プラン",
    rules: [{ rule: "need_select" }],
  },
]);

export const OrderUpdateCardValidation = new Validation([
  {
    key: ["card", "number"],
    name: "カード番号",
    rules: [{ rule: "need_input" }, { rule: "number" }],
  },
  {
    key: ["card", "name"],
    name: "カード名義",
    rules: [{ rule: "need_input" }],
  },
  {
    key: ["card", "yearmonth"],
    name: "有効期限",
    rules: [{ rule: "need_yearmonth" }],
  },
  {
    key: ["card", "cvc"],
    name: "セキュリティーコード",
    rules: [{ rule: "need_input" }, { rule: "number" }],
  },
]);
