import dialog from "@/vuex/dialog";

type ValidateRule = {
  key: string[];
  name?: string;
  rules?: { rule: string; error?: string }[];
  funcs?: { func: (object: any, args?: any) => string[]; error: string }[]; //returnはエラーメッセージ length == 0ならエラーなし
};

export default class Validation {
  validation: ValidateRule[] = [];

  constructor(validation?: ValidateRule[]) {
    this.validation = validation;
  }

  validate(object: any, args?: any): boolean {
    const error = [];
    for (const validation of this.validation) {
      if (validation.rules) {
        for (const rule of validation.rules) {
          if (rule.rule == "need_input") {
            if (!this.isset(this.getContent(object, validation.key)))
              error.push(rule.error ?? `${validation.name}を入力してください`);
          } else if (rule.rule == "need_select") {
            if (!this.isset(this.getContent(object, validation.key)))
              error.push(rule.error ?? `${validation.name}を選択してください`);
          } else if (rule.rule == "need_yearmonth" || rule.rule == "need_date") {
            if (!this.isset(this.getContent(object, validation.key)))
              error.push(rule.error ?? `${validation.name}を正しく入力してください`);
          } else if (rule.rule == "number") {
            if (
              this.isset(this.getContent(object, validation.key)) &&
              !this.isNumber(this.getContent(object, validation.key))
            )
              error.push(rule.error ?? `${validation.name}は半角数字で入力してください`);
          }
        }
      }
      if (validation.funcs) {
        for (const func of validation.funcs) {
          if (!func.func(this.getContent(object, validation.key), args)) {
            error.push(func.error);
          }
        }
      }
    }

    if (error.length != 0) {
      dialog.openErrorDialog({ error });
    }

    return error.length == 0;
  }

  getContent(object: any, key: string[]): any {
    let result = object;
    for (const each_key of key) {
      result = result[each_key] ?? null;
    }
    return result;
  }

  isset(val: any): boolean {
    if (val === undefined || val === null || val === "") {
      return false;
    } else {
      return true;
    }
  }
  isNumber(val: any): boolean {
    if (this.isset(val)) {
      val = `${val}`;
      if (val.match(/^-?[0-9]+$/)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
