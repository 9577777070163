import Control from "%/Control";
import {
  OrderChangePayMethodProp,
  OrderChangePlanProp,
  OrderRegistProp,
  OrderUpdateCardProp,
} from "@/types/Order";

export const OrderRegistControl = new Control<OrderRegistProp>({
  plan: 0,
  pay_method: 0,
  is_rental: false,
  card: {
    number: "",
    yearmonth: "",
    cvc: "",
    name: "",
  },
});

export const OrderChangePlanControl = new Control<OrderChangePlanProp>({
  plan: 0,
});

export const OrderChangePayMethodControl = new Control<OrderChangePayMethodProp>({
  pay_method: 0,
  card: {
    number: "",
    yearmonth: "",
    cvc: "",
    name: "",
  },
});

export const OrderUpdateCardControl = new Control<OrderUpdateCardProp>({
  card: {
    number: "",
    yearmonth: "",
    cvc: "",
    name: "",
  },
});
