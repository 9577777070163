import { reactive, UnwrapNestedRefs } from "vue";

export default class Control<T extends object = any> {
  control: UnwrapNestedRefs<T>;
  initialControl: T;

  constructor(initialControl: T) {
    this.initialControl = initialControl;
    this.control = reactive<T>(JSON.parse(JSON.stringify(initialControl)));
  }

  getObject() {
    return this.control as T;
  }
  resetObject() {
    Object.assign(this.control, JSON.parse(JSON.stringify(this.initialControl)));
  }
  setObject(object: T) {
    Object.assign(this.control, JSON.parse(JSON.stringify(object)));
  }
}
